import Vue from 'vue';
import RecentStories from './components/Biswire/RecentStories';
import SelectLeaders from './components/Jobs/SelectLeaders';

if (document.getElementById('app')) {
    const app = new Vue(
        {
            el: '#app',
            components: {
                'recent-stories': RecentStories,
                'select-leaders': SelectLeaders,
            }
        }
    )
}

// Testing code splitting to reduce bundle size
// 'purchase-product': () => import(
//     /* webpackChunkName: "js/PurchaseProduct" */
//     './components/Purchase/PurchaseProduct'
// ),
// 'event-confirmation-questions': () => import(
//     /* webpackChunkName: "js/EventConfirmationQuestions" */
//     './components/Event/EventConfirmationQuestions'
// ),
// 'receipt': () => import(
//     /* webpackChunkName: "js/Receipt" */
//     './components/Receipt/Receipt'
// ),
