<template>
    <div v-if="releases && releases.length" class="recentStories__container">
        <div class="recentStories__header">
            Recent Press Releases
        </div>
        <div class="recentStories__stories">
            <div v-for="release in releases" :key="release.id" class="recentStories__story">
                <a :href="release.link ? release.link : '#'">
                    <h6 v-if="release.headline" class="recentStories__headline">
                        {{ release.headline }}
                    </h6>
                    <p class="recentStories__classification">
                        <span class="recentStories__classification-market" v-if="release.market">
                            {{ release.market.name }}
                        </span>
                        <span class="recentStories__classification-topic" v-if="release.topic" :style="{ 'background-color': release.topic.color }">
                            {{ release.topic.name }}
                        </span>
                    </p>
                    <p v-if="release.date" class="recentStories__date">
                        {{ formatDate(release.date) }}
                    </p>
                </a>
            </div>
        </div>
        <div class="recentStories__more">
            <a :href="pressReleasesUrl">
                More Press Releases
            </a>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        props: {
            classification: {
                type: String,
                required: false,
            },
            pressReleasesUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                page: 1,
                perPage: 5,
                releases: []
            }
        },
        mounted() {
            this.getReleases()
        },
        methods: {
            async getReleases() {
                let url = `/api/press-releases?classification=${this.classification}&per_page=${this.perPage}&page=${this.page}`

                await axios.get(url).then(response => {
                    if (response && response.data && response.data.data && response.data.data.length) {
                        this.releases.push(...response.data.data)
                    }
                })
            },

            formatDate(releaseDate) {
                return new Date(releaseDate).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"})
            }
        }
    }
</script>
