<template>
    <div class="jobs__container">
        <div v-if="! loading && jobs.length > 0" class="jobs">
            <h2 class="jobs__header">SelectLeaders Jobs</h2>
            <ul class="jobs__list">
                <li v-for="(n, index) in 3" :key="index" class="jobs__job" v-if="jobs[index]">
                    <a :href="jobs[index].url" target="_blank" class="jobs__job-link">
                        <div v-if="jobs[index].headline" class="job__title">
                            {{ jobs[index].headline }}
                        </div>
                        <div v-if="jobs[index].organization" class="job__organization">
                            {{ jobs[index].organization }}
                        </div>

                        <div class="job__details">
                            <span v-if="jobs[index].locality" class="job__market">
                                {{ jobs[index].locality }}
                            </span>
                            <span v-if="jobs[index].date" class="job__date">
                                Posted {{ jobs[index].date }}
                            </span>
                        </div>
                    </a>
                </li>
            </ul>
            <a :href="moreJobsLink" target="_blank" class="jobs__link" v-if="moreJobsLink">
                More Job Opportunities
            </a>
        </div>
        <loading :active.sync="loading" :is-full-page="false" loader="spinner" />
    </div>
</template>

<script>
    import axios from 'axios';
    import Loading from 'vue-loading-overlay';

    export default {
        components: {
            Loading,
        },
        props: {
            jobsUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                jobs: [],
                loading: false,
                moreJobsLink: '',
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            formatDate(date) {
                return new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"})
            },
            getContent(job) {
                return {
                    date: this.formatDate(job.date),
                    url: job.url,
                    headline: job.title ? job.title : null,
                    locality: job.locality ? job.locality : job.region ? job.region : null,
                    organization: job.organization ?? null,
                };
            },
            async load() {
                this.loading = true;
                this.jobs = [];

                try {
                    const response = (await axios.get(this.jobsUrl)).data;
                    for (let i in response.data) {
                        this.jobs.push(this.getContent(response.data[i]));
                    }
                    this.moreJobsLink = response.meta.more ?? '';
                } catch(error) {
                    console.error('error!', error);
                }

                this.loading = false;
            },
        },
    };
</script>
